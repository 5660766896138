import axios from "../../../common/api/custom-axios";
import configs from "../../../configs";
import { FilesData } from "../models/files-data";
import { UploadCertificatesRequest } from "../models/upload-certificates-request";
import { UploadLicenseRequest } from "../models/upload-license-request";

export class SupportFilesService {
    private supportFilesRelUri = "support-files"
    private licenseRelUri = "license"
    private certificatesRelUri = "csca-certificates"

    getClientsData(): Promise<FilesData> {
        return axios
            .get([configs.endpoint, this.supportFilesRelUri].join("/"))
            .then(rsp => rsp.data);
    }

    uploadLicense(apiKey: string, request: UploadLicenseRequest) {
        var formData = new FormData()
        formData.append("file", request.file)
        return axios
            .post(
                [configs.endpoint, this.supportFilesRelUri, this.licenseRelUri, apiKey].join("/"),
                formData,
                {
                    params: { expiryDate: request.expiryDate },
                    headers: { 'Content-Type': 'multipart/form-data' }
                }
            )
            .then(rsp => rsp.data);
    }

    uploadCertificates(apiKey: string, request: UploadCertificatesRequest) {
        var formData = new FormData()
        formData.append("file", request.file)
        return axios
        .post(
            [configs.endpoint, this.supportFilesRelUri, this.certificatesRelUri, apiKey].join("/"),
            formData,
            {
                headers: { 'Content-Type': 'multipart/form-data' }
            }
        )
        .then(rsp => rsp.data);
    }

    deleteCertificate(apiKey: string, id: string) {
        return axios
            .delete(
                [configs.endpoint, this.supportFilesRelUri, this.certificatesRelUri, apiKey, id].join("/")
            )
            .then(rsp => rsp.data);
    }

    deleteLicense(apiKey: string) {
        return axios
            .delete([configs.endpoint, this.supportFilesRelUri, this.licenseRelUri, apiKey].join("/"))
            .then(rsp => rsp.data);
    }
}

const supportFilesService = new SupportFilesService()

export default supportFilesService